import React, { useState, useCallback } from "react";

import "./App.css";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import { Button, Modal } from "flowbite-react";
import { BiNotification, BiLike, BiDislike } from "react-icons/bi";
import { AlignLeft, List, Type } from "react-feather";

const firebaseConfig = {
  apiKey: "AIzaSyAM3G1DjUkhCSNisVJKzzhqJE978z5H0g8",
  authDomain: "smart-lorem-ipsum.firebaseapp.com",
  projectId: "smart-lorem-ipsum",
  storageBucket: "smart-lorem-ipsum.appspot.com",
  messagingSenderId: "169184368427",
  appId: "1:169184368427:web:379759aed88021d568576e",
  measurementId: "G-LRRQX4VP51",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App: React.FC = () => {
  const [inputValue, setInputValue] = useState("");
  const [outputValue, setOutputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInputValue(event.target.value);
    },
    [setInputValue]
  );

  const [type, setType] = useState<"Page Title" | "Paragraph" | "Snippet">(
    "Page Title"
  );
  const [value, setValue] = useState(3);

  const handleSliderChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(Number(event.target.value));
    },
    [setValue]
  );

  const handleRadioGroupChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setType(event.target.value as "Page Title" | "Paragraph" | "Snippet");
    },
    [setType]
  );

  const [isInputFocused, setIsInputFocused] = useState(true);

  const [modalVisible, setModalVisible] = React.useState(false);
  const handleCloseModalClick = React.useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const handleInputBlur = useCallback(() => {
    setIsInputFocused(false);
  }, [setIsInputFocused]);

  const handleFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!inputValue) return;

      setLoading(true);

      try {
        // const response = await axios.post('https://functions-e3mveopdja-uc.a.run.app/generateLoremIpsum', inputValue,
        // {
        //   headers: {
        //     'Content-Type': 'text/html',
        //   },
        // });
        // console.log(response.data)

        const url =
          "https://functions-e3mveopdja-uc.a.run.app/generateLoremIpsum";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "text/html",
          },
          body: `${
            type === "Snippet" ? "raw two lines excerpt" : type
          } text about the topic '${inputValue}'`,
        });

        const text = await response.text();
        const result = JSON.parse(text);

        if (result && Array.isArray(result) && result.length > 0) {
          const firstCandidate = result[0].candidates[0];
          const output = firstCandidate.output;

          setOutputValue(output);
          setModalVisible(true);
        }

        logEvent(analytics, "text_generation");

        // setInputValue('');
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    },
    [inputValue, setLoading, setOutputValue, value, type]
  );

  const [toastVisible, setToastVisible] = React.useState(true);

  const handleLikeClick = React.useCallback(() => {
    logEvent(analytics, "like");
    setToastVisible(false);
  }, [logEvent, analytics, setToastVisible]);
  const handleDislikeClick = React.useCallback(() => {
    logEvent(analytics, "like");
    setToastVisible(false);
  }, [logEvent, analytics, setToastVisible]);

  const handleToastCloseClick = React.useCallback(() => {
    setToastVisible(false);
  }, [logEvent, analytics, setToastVisible]);

  return (
    <div>
      <Modal
        show={modalVisible}
        onClose={handleCloseModalClick}
        className="m-auto"
      >
        <Modal.Header>Your custom text for '{inputValue}'</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              {outputValue && (
                <div className="m-4">
                  {/* <h4>Output:</h4> */}
                  <p style={{ textAlign: "justify", whiteSpace: "pre-wrap" }}>
                    {outputValue.split(/\*\*(.*?)\*\*/g).map((part, index) => {
                      if (index % 2 === 0) {
                        // Renderizar texto entre os títulos
                        return (
                          <span key={index}>{part.replaceAll("*", "•")}</span>
                        );
                      } else {
                        // Renderizar título
                        const title = part;
                        return <b key={index}>{title}</b>;
                      }
                    })}
                  </p>
                </div>
              )}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gradient-to-tl from-pink-900 via-black to-sky-700"
            onClick={handleFormSubmit}
          >
            {!loading ? (
              "Generate Again"
            ) : (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-6 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </Button>
          <Button color="gray" onClick={handleCloseModalClick}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{ position: "fixed", bottom: 16, right: 16 }}>
        <div
          id="toast-interactive"
          className="w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:bg-gray-800 dark:text-gray-400"
          role="alert"
          hidden={!toastVisible}
        >
          <div className="flex">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:text-blue-300 dark:bg-blue-900">
              <BiNotification />
            </div>
            <div className="ml-3 text-sm font-normal">
              <span className="mb-1 text-sm font-semibold text-gray-900 dark:text-white">
                👋 Hello there!
              </span>
              <div className="mb-2 text-sm font-normal">
                We are working to bring you the best website experience! How
                does it look like?
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <button
                    onClick={handleLikeClick}
                    className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                  >
                    <BiLike />
                  </button>
                </div>
                <div>
                  <button
                    onClick={handleDislikeClick}
                    className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                  >
                    <BiDislike />
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="ml-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              data-dismiss-target="#toast-interactive"
              aria-label="Close"
              onClick={handleToastCloseClick}
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="App">
        <div className="bg-gradient-to-tl from-pink-900 via-black to-sky-700">
          <nav className="transparent border-gray-200 dark:bg-gray-900">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
              <a href="https://flowbite.com/" className="flex items-center">
                <img
                  src="./assets/logo-light-large.png"
                  className="h-8 mr-3"
                  alt="AIpsum Logo"
                />
              </a>
            </div>
          </nav>

          <div className="w-full pl-8 pt-40 pr-8 pb-40 grid place-items-center">
            <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-white md:text-5xl lg:text-6xl dark:text-white">
              The new way of creating placeholder content
            </h1>
            <p className="mb-6 text-lg font-normal text-center text-gray-300 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
              AIpsum is the best tool to boost the quality of your work with
              relevant and productive placeholder text, fully adapted to your
              use case. Try it now!
            </p>
            <a
              href="#about"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
            >
              Learn more
              <svg
                className="w-3.5 h-3.5 ml-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="m-16">
          <form onSubmit={handleFormSubmit}>
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                What's your placeholder about/what is it for?
              </label>
              <textarea
                id="message"
                rows={4}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder={'e.g., "success alert", "welcome page"'}
                required
                value={inputValue}
                onChange={handleInputChange}
                // isValid={!!inputValue}
                // disabled={!isInputFocused && !inputValue}
                // isInvalid={!isInputFocused && !inputValue}
                onBlur={handleInputBlur}
              />
            </div>

            <h3 className="mb-5 text-lg font-medium text-gray-900 dark:text-white">
              Choose your content type
            </h3>
            <ul className="grid gap-2 md:grid-cols-3 w-max pl-0">
              <li>
                <input
                  type="radio"
                  id="page-title"
                  name="type"
                  value="Page Title"
                  className="hidden peer"
                  onChange={handleRadioGroupChange}
                  required
                  checked={type === "Page Title"}
                />
                <label
                  htmlFor="page-title"
                  className="inline-flex items-center justify-between w-32 p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div className="flex flex-col items-center">
                    <Type />
                    <div className="w-full text-lg font-semibold text-center">
                      Page Title
                    </div>
                    <div className="w-full text-center">
                      Good for section titles
                    </div>
                  </div>
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  id="paragraph"
                  name="type"
                  value="Paragraph"
                  className="hidden peer"
                  onChange={handleRadioGroupChange}
                  required
                  checked={type === "Paragraph"}
                />
                <label
                  htmlFor="paragraph"
                  className="inline-flex items-center justify-between w-32 p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div className="flex flex-col items-center">
                    <AlignLeft />
                    <div className="w-full text-lg font-semibold text-center">
                      Paragraph
                    </div>
                    <div className="w-full text-center">Good for content</div>
                  </div>
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  id="snippet"
                  name="type"
                  value="Snippet"
                  className="hidden peer"
                  required
                  onChange={handleRadioGroupChange}
                  checked={type === "Snippet"}
                />
                <label
                  htmlFor="snippet"
                  className="inline-flex items-center justify-between w-32 p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div className="flex flex-col items-center">
                    <List />
                    <div className="w-full text-lg font-semibold text-center">
                      Snippet
                    </div>
                    <div className="w-full text-center">
                      Good for descriptions
                    </div>
                  </div>
                </label>
              </li>
            </ul>

            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {!loading ? (
                "Submit"
              ) : (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-6 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </button>
          </form>
          <article id={"about"} className="format lg:format-lg mt-96">
            <h1>
              What exactly is <i>Lorem Ipsum</i>
            </h1>
            <p className="lead">
              When it comes to web design and content creation, one term that
              often comes up is "Lorem Ipsum." You might have seen it in
              placeholder text on websites or heard designers and developers
              mention it. But what exactly is Lorem Ipsum, and why is it so
              widely used?
            </p>
          </article>
          <article id={"where"} className="format lg:format-lg">
            <h1>Where is it from?</h1>
            <p className="lead">
              Lorem ipsum has a rich history and has become a ubiquitous tool in
              the world of design and typesetting. The origins of lorem ipsum
              can be traced back to ancient Rome, where Latin was the dominant
              language. It is believed that the text was derived from the works
              of Cicero, a Roman philosopher and orator. However, the exact
              source and authorship of the text remain uncertain.
            </p>
          </article>
          <article id={"benefits"} className="format lg:format-lg">
            <h1>What is AIpsum text generator?</h1>
            <p className="lead">
              <b>Visual Representation:</b> One of the main benefits of using
              Lorem Ipsum is that it provides a visual representation of the
              content layout. Designers can experiment with different fonts,
              sizes, and spacing to create a visually appealing design without
              having to wait for the actual content to be available. <br />
              <br />
              <b>Focus on Design Elements:</b> By using Lorem Ipsum, designers
              can focus on the design elements such as color schemes, images,
              and typography. They can ensure that the overall layout is
              aesthetically pleasing and well-balanced before integrating the
              final content.
              <br />
              <br />
              <b>Time-Saving:</b> Lorem Ipsum saves time during the initial
              design phase. Instead of waiting for the content to be finalized,
              designers can start working on the layout immediately. It helps
              streamline the design process and allows for quicker iterations
              and revisions.
              <br />
              <br />
              <b>Client Collaboration:</b> Lorem Ipsum also facilitates client
              collaboration. Designers can present the design concepts to
              clients using placeholder text, allowing them to provide feedback
              and make suggestions based on the visual representation. It
              promotes effective communication and ensures client satisfaction.
              <br />
              <br />
              <b>Versatile Application:</b> Lorem Ipsum is not limited to web
              design. It can be used in various design fields, including graphic
              design, packaging design, and advertising. Its versatility makes
              it a valuable tool for designers across different industries.
            </p>
          </article>
          <article id={"conclusion"} className="format lg:format-lg mb-96">
            <h1>Cool, what about AIpsum generator?</h1>
            <p className="lead">
              <b>AIpsum, the AI-powered Lorem Ipsum Generator</b> takes this old{" "}
              <i>Lorem Ipsum</i>
              concept to the <b>next level</b> by generating meaningful and
              contextually relevant lorem ipsum text. Powered by the PaLM
              Language Model from Google, the generator produces lorem ipsum
              that aligns with the desired topic and content style. It ensures
              that designers and developers can create more accurate and
              suitable placeholders that resonate with the intended context.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default App;
